import * as React from "react";
import type { GetState, SetState } from "zustand";
import create from "zustand";
import createContext from "zustand/context";
import { persist } from "zustand/middleware";
import type { FilesFoldersState } from "./stores/FilesFolders.Store";
import { FilesFoldersStore } from "./stores/FilesFolders.Store";
import type { UserStyleState } from "./stores/UserStyles.Preferences.store";
import { styleUserPreferencesStore } from "./stores/UserStyles.Preferences.store";
import type { UserTaskImportSaveFolderState } from "./stores/UserTaskImportSaveFolder.Preferences.store";
import { taskImportSaveFolderUserPreferencesStore } from "./stores/UserTaskImportSaveFolder.Preferences.store";
import type { UserTasksPreferencesState } from "./stores/UserTasks.prefeferences";
import { userTasksPreferencesStore } from "./stores/UserTasks.prefeferences";

// Tipo combinado de las preferencias del usuario
type UserPreferences = FilesFoldersState &
  UserStyleState &
  UserTaskImportSaveFolderState &
  UserTasksPreferencesState;

// Crear el contexto de Zustand
const { Provider, useStore: useUserPreferences } =
  createContext<UserPreferences>();

type UserPreferencesProviderProps = {
  children: React.ReactNode;
};

function UserPreferencesProvider(props: UserPreferencesProviderProps) {
  return (
    <Provider
      createStore={() =>
        create<UserPreferences>(
          persist(
            (set, get) => ({
              ...styleUserPreferencesStore(
                set as unknown as SetState<UserStyleState>
              ),
              ...taskImportSaveFolderUserPreferencesStore(
                set as unknown as SetState<UserTaskImportSaveFolderState>,
                get as unknown as GetState<UserTaskImportSaveFolderState>
              ),
              ...FilesFoldersStore(
                set as unknown as SetState<FilesFoldersState>,
                get as unknown as GetState<FilesFoldersState>
              ),
              ...userTasksPreferencesStore(
                set as unknown as SetState<UserTasksPreferencesState>
              ),
            }),
            {
              getStorage: () => localStorage,
              name: "user-preferences-storage",
            }
          )
        )
      }
    >
      {props.children}
    </Provider>
  );
}

export { UserPreferencesProvider, useUserPreferences };
