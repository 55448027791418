import type { SetState } from "zustand";

type UserTasksPreferencesState = {
  unassignedTaskPopup: boolean;
  setUnassignedTaskPopup: (unassignedTaskPopup: boolean) => void;
};

const userTasksPreferencesStore = (
  set: SetState<UserTasksPreferencesState>
) => ({
  setUnassignedTaskPopup: (unassignedTaskPopup: boolean) =>
    set({ unassignedTaskPopup }),
  unassignedTaskPopup: false,
});

export type { UserTasksPreferencesState };
export { userTasksPreferencesStore };
